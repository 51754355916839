<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="mt-4">
      <i class="fa fa-backward"></i>
      <router-link :to="{ name: AuthorPath.name }"> Authors </router-link>
    </div>
    <b-card header="Create New Author" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <b-form @submit="onSubmit" @reset="onReset">
        <b-row>
          <b-col md="6">
            <b-form-group label="Name">
              <b-form-input v-model="form.name" type="text" placeholder="Author Name" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Birth Date">
              <b-form-datepicker today-button id="birthdate" autocomplete="off"
                v-model="form.birthdate"></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="4">
            <b-form-group label="First">
              <b-form-input v-model="form.first_name" type="text" placeholder="First Name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group label="Last">
              <b-form-input v-model="form.last_name" type="text" placeholder="Last Name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group label="Academic Title">
              <b-form-input v-model="form.academic_title" type="text" placeholder="example: S.H., M.H."></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Description">
              <b-form-textarea id="description" v-model="form.meta" placeholder="Enter something..."
                rows="5"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox v-model="form.isActive" :value="true" :unchecked-value="false">Active
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="mx-1" type="submit" variant="primary">Submit</b-button>
            <b-button class="mx-1" type="reset" variant="secondary">Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { AuthorPath } from '../../router/content';

export default {
  data() {
    return {
      form: {
        name: null,
        isActive: true,
        birthdate: null,
        first_name: null,
        last_name: null,
        academic_title: null,
        meta: null,
      },
      AuthorPath,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.author.isLoading,
      isError: (state) => state.author.isError,
      errorMessage: (state) => state.author.errorMessage,
      successMessage: (state) => state.author.successMessage,
    }),
  },
  watch: {
    errorMessage: function (message) {
      if (!this.errorMessage) return;
      this.messageAlert('error', message, 5000);
    },
    successMessage: function (message) {
      if (!this.successMessage) return;
      this.messageAlert('success', message);
      this.$router.push(this.AuthorPath);
    },
  },
  methods: {
    ...mapActions('author', [
      'postAuthor',
    ]),
    onSubmit(event) {
      event.preventDefault();
      const payload = this.form;
      this.postAuthor(payload);
    },
    onReset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
}
</script>